import React, { useEffect, useState } from "react";
import "../../CustomCss/CustomStyle.css";
import "../../CustomCss/Margins.css";
import {Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
//import NauApp1 from "../../Images/Mockups/InspectNau-Top.png";
//import BGShips from "../../Images/technological.jpg";
//import BGShips1 from "../../Images/Bi/large-container-ship-is-carrying-cargo-water_1302547-11089.jpg";
import Chart1 from "../../Images/Mockups/InspectNau-Top.png";
import Chart2 from "../../Images/BiMockup2.png";
import Chart3 from "../../Images/Bi/VC-Card_Mockup.png";
import Chart4 from "../../Images/Bi/WiredGlobe.jpg";
import mt1 from '../../Images/Ourteam/team/27.jpg'
//import OrioApps from "../../Images/orionApps.png";
import OrionWideLogo from "../../Images/OrionLogo.svg";
import globe from "../../Images/Bi/digital-globe.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/fontawesome-svg-core";
import {faEnvelope} from "@fortawesome/fontawesome-free-solid";
import {} from "@fortawesome/fontawesome-free";

import {
  faLinkedin
  
} from "@fortawesome/free-brands-svg-icons";



function KaniAg() {
  const [isMobile, setIsMobile] = useState(true);
  const [isPortrait, setIsPortrait] = useState(true);

  // Save the visiting card
  const handleDownloadVCard = () => {
    const name = "KANISHKA AGARWAL";
    const phoneNumber = "+91 8890803412";
    const officeNumber1 = "+911244209280";
    const officeNumber2 = "+911244036622";
    const email = "kanishka@orionmarineconcepts.com";
    const website = "https://www.orionmarineconcepts.com"; 

    const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN:${name}
TEL;TYPE=CELL:${phoneNumber}
TEL;TYPE=WORK,VOICE:${officeNumber1}
TEL;TYPE=WORK,VOICE:${officeNumber2}
EMAIL;TYPE=WORK:${email}
URL:${website}
END:VCARD
    `.trim();

    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);

    // Open in a new tab (better for iOS)
    //window.open(url, "_blank");

      // Create a temporary <a> element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "Kanishka_Agarwal.vcf"; // Set the filename
      document.body.appendChild(a);
      a.click();  // Simulate click to download
      document.body.removeChild(a); // Clean up

    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 3000); // Delay for iOS compatibility
};

  
  



  useEffect(() => {
    const checkDevice = () => {
      const isMobileDevice = window.matchMedia("(max-width: 768px)").matches;
      const isPortraitMode = window.matchMedia("(orientation: portrait)").matches;

      setIsMobile(isMobileDevice);
      setIsPortrait(isPortraitMode);
    };

    // Initial check
    checkDevice();

    // Listen for changes
    window.addEventListener("resize", checkDevice);
    window.addEventListener("orientationchange", checkDevice);

    return () => {
      window.removeEventListener("resize", checkDevice);
      window.removeEventListener("orientationchange", checkDevice);
    };
  }, []);

  if (!isMobile || !isPortrait) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f8f9fa",
          textAlign: "center",
        }}
      >
        <div>
          <h2 style={{ color: "#dc3545" }}>Unsupported Device</h2>
          <p>Please use a mobile device in portrait mode to view this page.</p>
        </div>
      </div>
    );
  }

  return (
    <Container fluid className="p-l-0 p-r-0" style={{minHeight:"100vh"}}>
      <div className="page-content" style={{minHeight:"100vh"}}>
    

        <header
          id="site_header"
          className="header mobile-menu-hide"
          style={{ position: "relative",minHeight:"160px" }}
        >
          <div className="header-content">
            <div className="header-photo">
            <img src={mt1} alt="Director Operations and Technical" />
            </div>
            <div className="header-titles">
              <h2>KANISHKA AGARWAL</h2>
              <h4>Digital Marketing Manager</h4>
             

              <div style={{ lineHeight: "1" }}>
                <a
                  style={{
                    margin: "0px !important",
                    color: "white",
                    fontSize: "1rem",
                  }}
                  href="mailto:kanishka@orionmarineconcepts"
                >
                  kanishka@orionmarineconcepts.com
                </a>
              </div>
              <div>
                <a
                  style={{
                    margin: "0px !important",
                    color: "white",
                    fontSize: "1rem",
                  }}
                  className="mb-2"
                  href="https://www.orionmarineconcepts.com" target="_blank" rel="noreferrer"
                >
                  www.orionmarineconcepts.com
                </a>
              </div>
              <div className="social-links">
                <ul className="mb-1">
                 
                  <li>
                    <a href="https://www.linkedin.com/in/createcontentwithkanishka" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li>
                    <a href="mailto:kanishka@orionmarineconcepts" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                  </li>
                 
                </ul>
              </div>

             
              <span style={{  position: "absolute", right: "4px", top: "1px" }}>
                <img
                  src={OrionWideLogo}
                  alt="logo"
                  className="site_headerLogo mt-2"
                  style={{ width: "40px" }}
                />
              </span>
            </div>
          </div>
          <div className="social-links" style={{ display: "block" }}>
                <button
                  style={{
                    padding: "3px 10px",
                    borderRadius: "5px",
                    color: "rgb(3, 13, 39)",
                    fontWeight:"bold",
                    backgroundColor: "white !important",
                    borderColor: "white !important",
                    margin: "5px",
                    fontSize: "16px",
                    width: "calc(100% - 10px)",
                  }}
                  onClick={handleDownloadVCard}
                >
                  Save Contact
                </button>
              </div>
        </header>
        <div
          className="content-area"
          style={{
            minHeight: "calc(100vh - 254px)",
            backgroundImage: `url(${Chart4})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            className="animated-sections"
            style={{
              height: "100%",
              position: "relative",
              backgroundColor: "transparent",
            }}
          >
            <section
              data-id="about-me"
              className="animated-section ps ps--theme_default ps--active-y section-active"
              data-ps-id="776c5061-ec44-ace8-2a44-be5e2ab2ac25"
              style={{
                height: "calc(100% - 70px)",
                backgroundColor: "transparent",
                //backgroundImage: `url(${BGShips1})`,
                // backgroundPosition: "-340px 0",
              }}
            >
              <div className="section-content">
                <div className="row text-start" style={{ margin: "0" }}>
                  <div className="col-7" style={{ lineHeight: "1.2",padding:"0" }}>
                    {/* <h5 className="SaasHead">Orion SAAS Portfolio</h5> */}
                    <div className="R_Box">
                      <ul>
                        <li>
                          <Link to="/products/QHSSE">QHSSE Guardian</Link>
                        </li>
                        <li>
                          <Link to="/products/Planned-Maintenance-System">
                            Planned Maintenance System
                          </Link>
                        </li>
                        <li>
                          <Link to="/products/supply-Chain">
                            Purchase and Procurement
                          </Link>
                        </li>
                        <li>
                          <Link to="/products/Crew-Management-System">
                            Crew Management
                          </Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="/products/Electronic-Document-Management-System">
                            Electronic SMS
                          </Link>
                        </li>
                        <li>
                          <Link to="/products/fleet-management">
                            Fleet Performance Monitoring
                          </Link>
                        </li>

                        <li>
                          <Link to="/products/fleet-management">
                            MRV and IMO DCS Services
                          </Link>
                        </li>

                        <li>
                          <Link to="/products/fleet-management">
                            GHG and CII Monitoring
                          </Link>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link to="/products/ECloud">E-Cloud DocVault</Link>
                        </li>

                        <li>
                          <Link to="/products/fleet-management">
                            E-Logs Marpol Annex
                          </Link>
                        </li>
                        <li>
                          <Link to="/products/fleet-management">
                            E-MARPOL Seal Log
                          </Link>
                        </li>
                        <li>
                          <Link to="/Orion-Bi">Microsoft BI Enabled</Link>
                        </li>
                      </ul>
                    </div>
                    {/* <h5 className="SaasHead">Mobile Applications</h5> */}
                    <div className="R_Box">
                      <ul>
                        <li>
                          <Link to="/Inspect-Nau-App">
                            InspectNAU - SIRE 2.0, RISQ 3.1
                          </Link>
                        </li>
                        <li>
                          <Link to="/products/Fleet-Performance-Mobile-App">
                            NAU Fleet Performance
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-5" style={{paddingRight:"0"}}>
                    <div className="col-12 p-r-0">
                      <div className="Maritime_Solutions">
                        <img
                          src={Chart3}
                          alt="Products"
                          className="img-fluid m-t-20"
                        />
                      </div>
                    </div>

                    <div className="col-12 p-r-0">
                      <div className="Maritime_Solutions">
                        <img
                          src={Chart1}
                          alt="Products"
                          className="img-fluid m-t-20"
                        />
                      </div>
                    </div>

                    <div className="col-12 p-r-0">
                      <div className="Maritime_Solutions">
                        <img
                          src={Chart2}
                          alt="Products"
                          className="img-fluid m-t-20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Row
                  className="m-l-0 m-r-0 pull-left"
                  style={{ width: "100%" }}
                >
                  <Col xs={12} className="p-l-0 p-r-0">
                    <Row className="m-l-0 m-r-0">
                      <Col xs={6} className="p-l-0 p-r-0">
                        <div className="Maritime_Solutions">
                          <img
                            src={Chart1}
                            alt="Orion Chart"
                            className="img-fluid"
                          />
                        </div>
                      </Col>

                      <Col xs={6} className="p-l-0 p-r-0">
                        <div className="Maritime_Solutions">
                          <img
                            src={Chart2}
                            alt="Products"
                            className="img-fluid"
                            style={{
                              filter: "drop-shadow(2px 9px 5px #000000)",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}
              </div>
            </section>
          </div>
        </div>
        <Row
          className="m-l-0 m-r-0"
          style={{ minHeight: "64px", width: "100%" }}
        >
          <Col
            xs={12}
            className="p-l-0 p-r-0"
            style={{
              backgroundImage:
                "linear-gradient(to right, #d2e6e7 75%, #aecdd8)",
            }}
          >
            <Row className="m-l-0 m-r-0" style={{ position: "relative" }}>
              <Col xs={9} className="p-l-0 p-r-0">
                <div
                  style={{
                    position: "absolute",
                    left: "3%",
                    width: "95%",
                    top: "10%",
                  }}
                >
                  <div className="label_Connect">Address :-</div>

                  <p className="p-t-10 p-b-5 label_address">
                    Unit no 116, First Floor, Suncity Business Tower, Sector-54,
                    Golf Course Road, Gurugram, Haryana, India – 122002
                  </p>
                </div>
              </Col>
              <Col xs={3} className="p-l-0 p-r-0">
                <span className="pull-right">
                  <img src={globe} alt="Orion Chart" className="img-fluid" />
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default KaniAg;
